'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { useAppDispatch } from '@akinon/next/redux/hooks';
import { resetHeaderState } from '@akinon/next/redux/reducers/header';
import { closeMiniBasket } from '@akinon/next/redux/reducers/root';
import { ROUTES } from '@theme/routes';
import { GoogleTagManager } from '@next/third-parties/google';
import { pushPageView } from '@theme/utils/gtm';
import { useLocalization } from '@akinon/next/hooks';
import Script from 'next/script';

export default function RootTemplate({
  children
}: {
  children: React.ReactNode;
}) {
  const { locale } = useLocalization();
  const dispatch = useAppDispatch();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    dispatch(closeMiniBasket());
    dispatch(resetHeaderState());
  }, [dispatch, pathname, searchParams]);

  useEffect(() => {
    if (pathname === ROUTES.HOME) {
      window.scrollTo(0, 0);
    }
    pushPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    if (locale === 'en') {
      window?.EKOCCSChat?.hideChatBtn?.();
    }
  }, []);

  return (
    <>
      {/* Google Tag Manager */}
      <GoogleTagManager
        gtmId={process.env.NEXT_PUBLIC_GTM_KEY || 'GTM-WCRM4L7'}
      />
      {/* End Google Tag Manager */}

      {children}
      <Script
        src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.3/jquery.min.js"
        integrity="sha512-SFaNb3xC08k/Wf6CRM1J+O/vv4YWyrPBSdy0o+1nqKzf+uLrIBnaeo8aYoAAOd31nMNHwX8zwVwTMbbCJjA8Kg=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
        strategy="lazyOnload"
        defer
      ></Script>
      <script data-brandguid="016C7524-08F1-4D83-927E-441725AF24CA"></script>
      <Script
        strategy="lazyOnload"
        src="https://cc-spectochat.echoccs.com/chat/initchat.js"
        defer
      ></Script>
      <Script
        src="https://bundles.efilli.com/d7821d72d769498baee43bc60b3891aa.lb.akinoncloud.com.prod.js"
        strategy="beforeInteractive"
        defer
      />
      <Script
        id="insider"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            function loadInsiderCookieFree() {
              if (!window.efilliSdk) {
              console.error('efilliSdk is not defined');
                return;
              }
              const marketingAllowed = window.efilliSdk.consentManager.isAllowed('marketing');
              if (marketingAllowed) {
                window.insiderFlow = 3;
                return;
              }
              window.insiderFlow = 1;
              const insiderScript = document.createElement('script');
              insiderScript.src = 'https://pasabahce.api.useinsider.com/ins.js?c=0';
              document.head.appendChild(insiderScript);
            }
            loadInsiderCookieFree();
          `
        }}
      />
    </>
  );
}
